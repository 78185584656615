import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import roadmap2 from "../../../../assets/images/roadmap2.png";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;

  background-image: url(${roadmap2});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  @media (max-width: 599px) {
  }
`;

const RoadMap2 = () => {
  return (
    <>
      <SectionWraper id="home">
        <InnerContinerWraper>
          <CustomGrid
            container
            fd="column"
            jc="end"
            p="50px 0"
            sx={{ minHeight: "100vh" }}
          >
            <CustomGrid item xs={12}>
              <Paragraph color="#fff">
                Solana HQ after we uhhh, deliver.
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default RoadMap2;
