import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  SectionHeading,
  StyledSpan,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import VideoBg from "../../../../assets/images/heroVideo.mp4";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100dvh);
  width: 100%;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const HeroSection = () => {
  return (
    <>
      <SectionWraper id="home">
        <AbsoluiteBackgroundVideo src={VideoBg} muted autoPlay loop />
      </SectionWraper>
    </>
  );
};

export default HeroSection;
