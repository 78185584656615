import React from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";

import aboutBg from "../../../../assets/images/aboutBg.png";
import plane from "../../../../assets/images/plane.png";
import aboutBottom from "../../../../assets/images/aboutBottom.png";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh);
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  position: relative;
  @media (max-width: 599px) {
  }
`;

const AboutSection = () => {
  return (
    <>
      <SectionWraper id="about">
        <InnerContinerWraper sx={{ paddingY: "100px" }}>
          <CustomGrid container jc="center">
            <CustomGrid container item xs={12} fd="column" ai="end" g="30px">
              <CustomGrid
                item
                xs={12}
                sm={9}
                md={7}
                fd="column"
                ai="end"
                g="30px"
              >
                <Paragraph variant="h1" textAlign="right">
                  Coming soon, <br />a
                  to a house <br />
                  near you! <br />
                </Paragraph>
                <Box sx={{display:"flex",flexWrap:"wrap",gap:"20px 40px",justifyContent:"center",position:{xs:"absolute",sm:"relative"},bottom:{xs:"10%",sm:"auto"}}}>
                  <CustomButton w="220px" b="none">Buy now</CustomButton>
                  <CustomButton w="220px" bgc="#B22234" hbgc="#B22234">Chart</CustomButton>
                </Box>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
          <AbsoluiteImage
            mw="12%"
            mw599="35%"
            style={{
              bottom: "-8%",
              right: "40%",
            }}
            src={aboutBottom}
          />
        </InnerContinerWraper>
        <AbsoluiteImage
          mw="75%"
          style={{
            top: "5%",
            left: "-35%",
          }}
          src={plane}
        />
      </SectionWraper>
    </>
  );
};

export default AboutSection;
