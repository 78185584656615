import React, { useEffect, useState } from "react";
import {
  Absoluite2BackgroundVideo,
  AbsoluiteBackgroundVideo,
  AbsoluiteImage,
  CustomGrid,
  PageWrapper,
} from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import Tokenomics from "../../Components/Sections/S3-Tokenomics";
import RoadMap, {
  InnerContinerWraper,
} from "../../Components/Sections/S4-Roadmap";
import Community from "../../Components/Sections/S5-Community";
import Disclaimer from "../../Components/Sections/S6-Disclaimer";
import styled from "@emotion/styled";
import RoadMap2 from "../../Components/Sections/S4-Roadmap copy";
import RoadMap3 from "../../Components/Sections/S4-Roadmap copy 2";
import VideoBg from "../../../assets/images/heroVideo.mp4";
import shade from "../../../assets/images/shade.png";
import music from "../../../assets/images/music.mp3";
import welcomBg from "../../../assets/images/welcomBg.png";
import flag from "../../../assets/images/flag.png";
import Paragraph from "../../Components/Paragraph";
import { Container } from "@mui/material";
import CustomButton from "../../Components/CustomBtn";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100dvh);
  width: 100%;
  position: relative;
  background-color: #000;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const CntainerWraper1 = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${welcomBg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px !important;
  position: relative;
  @media (max-width: 599px) {
    padding: 0px !important;
  }
`;
const BgGrid = styled(CustomGrid)`
  background-image: url(${flag});
  background-position: center;
  background-size: contain;
  flex-direction: column;
  background-repeat: no-repeat;
  min-height: 390px;
  justify-content: end;
  gap: 20px;
`;
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const [isWelcome, setIsWelcome] = useState(false);
  const [skip, setSkip] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [timerId, setTimerId] = useState(null);

  const startTimeout = () => {
    const timeoutId = setTimeout(() => {
      // Your code to execute after 42 seconds
      // setSkip(true);
      setIsPlaying(false);
    }, 11000); // 12 seconds in milliseconds
    setTimerId(timeoutId);
  };

  const cancelTimeout = () => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
      setSkip(true);
      setIsPlaying(false);
    }
  };
  const handleStory = () => {
    setIsWelcome(true);
    startTimeout();
  };
  const handleSkip = () => {
    setIsPlaying(true);
    setIsWelcome(true);
    setSkip(true);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Your code to execute after 42 seconds
      console.log("42 seconds have passed!");
    }, 11000); // 42 seconds in milliseconds

    // Clear the timeout if the component unmounts or the effect is re-run
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  return (
    <>
      {isWelcome ? (
        <PageWrapper>
          {isPlaying ? (
            <SectionWraper>

            <Absoluite2BackgroundVideo src={VideoBg} autoPlay loop />
            </SectionWraper>
          ) : (
            <>
              <audio src={music} autoPlay loop />
              <AboutSection />
              <Tokenomics />
              <RoadMap />
              <Community />
              <Disclaimer />
              <RoadMap2 />
              <RoadMap3 />
            </>
          )}
        </PageWrapper>
      ) : (
        <CntainerWraper1>
          <Container>
            <CustomGrid container sx={{ minHeight: "100vh" }}>
              <BgGrid container item xs={12} sm={10} md={8}>
                <CustomGrid
                  item
                  xs={12}
                  sm={8}
                  fd="column"
                  jc="end"
                  bgcolor="#00000063"
                  borderRadius="20px"
                  p="10px"
                  g="10px"
                  m="0 0 10px"
                >
                  <Paragraph
                    color="#fff"
                    variant="h4"
                    sx={{ textTransform: "uppercase" }}
                  >
                    Does your country have oil?
                  </Paragraph>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <CustomButton p="0px 20px" onClick={handleStory}>
                      YES
                    </CustomButton>
                    <CustomButton p="0px 20px" bgc="#B22234" hbgc="#B22234">
                      NO
                    </CustomButton>
                  </div>
                </CustomGrid>
              </BgGrid>
            </CustomGrid>
          </Container>
        </CntainerWraper1>
      )}
    </>
  );
};

export default MainPage;
