import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import VideoBg from "../../../../assets/images/contractImage.png";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;

  background-image: url(${VideoBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  @media (max-width: 599px) {
  }
`;

const Tokenomics = () => {
  return (
    <>
      <SectionWraper id="home">
        <InnerContinerWraper>
          <CustomGrid
            container
            fd="column"
            jc="space-between"
            p="50px 0"
            sx={{ minHeight: "100vh" }}
          >
            <CustomGrid item xs={12}>
              <Paragraph color="#fff">
                Sign our Billion dollar <br /> contract to help deliver uh.. stuff.
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} fd="column">
              <Paragraph color="#fff">Contract:</Paragraph>
              <Paragraph color="#fff" variant="h6">Network: Solana</Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Tokenomics;
