import styled from "@emotion/styled";
import { Container, Link } from "@mui/material";
import React from "react";
import VideoBg from "../../../../assets/images/comunityVideo.mp4";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import community from "../../../../assets/images/community.png";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  DataImage,
  SectionHeading,
  StyledSpan,
} from "../../../Styles/style";
import Tiker from "../../marquetag";
import Paragraph from "../../Paragraph";
const SectionWraper = styled.div`
  overflow: hidden;

  background-image: url(${community});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  @media (max-width: 599px) {
  }
`;

const Community = () => {
  return (
    <>
      <SectionWraper id="home">
        <InnerContinerWraper>
          <CustomGrid container jc="end" p="50px 0" sx={{ minHeight: "100vh" }}>
            <CustomGrid item xs={12} jc="end">
              <Paragraph color="#fff" textAlign="right">
                Sorry, we don't do taxes. <br />
                What do we look like <br />
                to you, the IRS!? <br />
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Community;
