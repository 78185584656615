import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";

const ParagraphText = styled(Typography)`
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    font-size: 40px;
  }
`;

const Paragraph = (props) => {
  const { children } = props;
  return (
    <ParagraphText
      variant="h2"
      color="#000000"
      fontFamily="impact"
      textAlign="center"
      padding="0px"
      margin="0px"
      lineHeight={1.1}
      {...props}
    >
      {children}
    </ParagraphText>
  );
};

export default Paragraph;
