import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import roadmap3 from "../../../../assets/images/roadmap3.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;

  background-image: url(${roadmap3});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  @media (max-width: 599px) {
  }
`;

const RoadMap3 = () => {
  return (
    <>
       <SectionWraper id="home">
        <InnerContinerWraper>
          <CustomGrid
            container
            fd="column"
            jc="space-between"
            p="50px 0"
            sx={{ minHeight: "100vh" }}
          >
            <CustomGrid item xs={12}>
              <Paragraph color="#fff">
              Join us at the pumps!
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} fd="column" g="20px">
              <Link href="" target="blank">
                <DataImage src={tg}  mw="100px" />
              </Link>
              <Link href="" target="blank">
                <DataImage src={x} mw="100px" />
              </Link>
            </CustomGrid>
            <CustomGrid item xs={12} fd="column">
              <Paragraph color="#fff" variant="h6">info@freedom.finance</Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default RoadMap3;
