import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import disclamer from "../../../../assets/images/disclamer.png";
import {
  CustomGrid,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const SectionWraper = styled.div`
  overflow: hidden;

  background-image: url(${disclamer});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  @media (max-width: 599px) {
  }
`;
const Disclaimer = () => {
  return (
    <>
    <SectionWraper id="home">
      <InnerContinerWraper>
        <CustomGrid
          container
          fd="column"
          jc="space-between"
          p="50px 0"
          sx={{ minHeight: "100vh" }}
        >
          <CustomGrid item xs={12}>
            <Paragraph color="#fff">
            Our total supply is uhhh,
            </Paragraph>
          </CustomGrid>
          <CustomGrid item xs={12}>
            <Paragraph color="#fff">idk but we have like 1 Billion of them!</Paragraph>
          </CustomGrid>
        </CustomGrid>
      </InnerContinerWraper>
    </SectionWraper>
  </>
  );
};

export default Disclaimer;
